const htmlContent = `
<div id="markdown">
<h1 id="the-terraform-statefile-in-public-clouds">The Terraform Statefile in Public Clouds</h1>
<h2 id="intro">Intro</h2>
<p>You have</p>
<ol>
<li>a git repo with your IaC code</li>
<li>credentials that permit for using an account in GCP, Azure or AWS  </li>
</ol>
<p>With those two assets perform two steps:</p>
<pre><code>git clone &lt;git URL&gt;
./&lt;repo name&gt;/deploy.sh &lt;credentials&gt;
</code></pre>
<p>This process sets up your infrastructure, builds and tests your application, promotes it through various stages, and ultimately deploys it to production. Execute these steps repeatedly within your team and in your CI/CD pipeline, expecting consistent outcomes.</p>
<h2 id="detailed-overview">Detailed Overview</h2>
<p>Using Terraform alongside a statefile is a best practice. The statefile contains references to all resources defined in the .tf files, allowing Terraform to determine what needs to be created, modified, or deleted.</p>
<p>It’s crucial that the statefile is stored remotely to prevent concurrent executions. For this guide, we assume an S3 bucket will be used to store the statefile.</p>
<h2 id="creating-the-remote-statefile">Creating the Remote Statefile</h2>
<p>Some recommend creating the bucket using Terraform, but this can lead to a &quot;chicken-and-egg&quot; scenario since it would require another statefile for this initial step.</p>
<p>A more effective method is to use cloud CLI commands to check if the bucket exists and create it if it doesn’t. This approach is applicable across all public clouds, and I will provide detailed commands and code examples for GCP, AWS, and Azure.</p>
<h2 id="the-three-scenarios">The Three Scenarios</h2>
<p>When you run terraform apply, one of the following situations will occur:</p>
<ol>
<li><strong>First Run:</strong> Terraform has never been executed before. There is no remote statefile and no existing resources.</li>
<li><strong>Different Environment:</strong> Terraform has been run previously, but not in your current environment. A remote statefile exists, but not locally, requiring some initialization.</li>
<li><strong>Same Environment:</strong> Terraform has been executed in the same environment before. The statefile is present both remotely and locally.</li>
</ol>
<p>In any of these cases, you should be able to run the command:</p>
<pre><code>./deploy.sh &lt;some credentials&gt;
</code></pre>
<p>and expect that the resources are created as defined in the .tf files, with the statefile reflecting the actual infrastructure.</p>
<!--
Sidenote: the requirement for a build system or CI/CD pipeline to be functional in the described 3 situations applies to all tools and techniques, not just Terraform. It is rather generally valid.
-->

<h2 id="purpose-of-this-article">Purpose of This Article</h2>
<p>You might wonder why I’m sharing this information when these practices seem straightforward. The reality is that many projects struggle to implement a simple workflow, making it impossible to recreate deployments from scratch in case of failure. Manual steps are often necessary to address the scenarios mentioned above, complicating bug reproduction for operations teams due to challenges in setting up environments.</p>
<!--
 They  'Three Scenarios' described above. Or manual editing was necessary to make things run.
IT projects always look great from outside.
-->

<h2 id="next-parts">Next Parts</h2>
<p><a href="/terraform-statefile-gcp">Part 2: How to use the terraform statefile: GCP</a><br><a href="/terraform-statefile-aws">Part 3: How to use the terraform statefile: AWS</a><br><a href="/terraform-statefile-azure">Part 4: How to use the terraform statefile: Azure</a>  </p>

</div>
`;
export default htmlContent
