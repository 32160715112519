const htmlContent = `
<div id="markdown">
<h1 id="matomo-behind-traefik">Matomo behind Traefik</h1>
<h2 id="intro">Intro</h2>
<p>I run a number of root servers for different projects.
You can host many services with arbitrary domains, URLs and ports fairly easily
only with the help of Traefik.<br>You need a working DNS that points these domains to the server where Traefik is running, of course.</p>
<p>In this example you route route requests to your homepage and to Matomo using Traefik as ingress controller.</p>
<h2 id="prerequisites">Prerequisites</h2>
<ol>
<li>Clone <a href="https://github.com/ludwigprager/matomo-behind-traefik">this repository</a>.  <pre><code>git clone https://github.com/ludwigprager/matomo-behind-traefik
</code></pre>
</li>
<li>Replace all occurrences (<a href="https://github.com/ludwigprager/matomo-behind-traefik/blob/main/configuration/conf.yaml#L6">here</a>, <a href="https://github.com/ludwigprager/matomo-behind-traefik/blob/main/configuration/conf.yaml#L15">here</a> and <a href="https://github.com/ludwigprager/matomo-behind-traefik/blob/main/traefik.yaml#L25">here</a>) of <code>example.com</code> with your domain.</li>
<li>Have your DNS point the subdomains <code>www.&lt;yourdomain&gt;</code> and <code>matomo.&lt;yourdomain&gt;</code> to this server.  </li>
</ol>
<h2 id="start-traefik">Start Traefik</h2>
<p>Now start Traefik with</p>
<pre><code>./matomo-behind-traefik/restart.sh
</code></pre>
<p>The certificates are signed and put in place.
But Traefik still cannot reach any service.</p>
<h2 id="start-the-workloads">Start The Workloads</h2>
<ul>
<li>run your homepage in a docker container with name <code>homepage</code></li>
<li>run Matomo in a docker container with name <code>matomo</code></li>
<li>both containers must reside in the same docker-network as Traefik</li>
</ul>
<h2 id="a-few-remarks">A Few Remarks</h2>
<ul>
<li>Valid certificates are automatically generated, stored, renewed.</li>
<li>Don&#39;t perform the <code>restart.sh</code> too frequently. Otherwise, there is a retention period of one week. See <a href="https://letsencrypt.org/docs/rate-limits/">this post from Let&#39;s Encrypt</a>.</li>
<li>Changes in <code>configuration/conf.yaml</code> are monitored, i.e. no restart is required when modifying this file.</li>
<li>http-requests to port 80 are forwarded to TLS on port 443.</li>
<li>the Traefik dashboard is reachable on port <code>:8123</code>. You might want to block port 8123 (the Traefik dashboard) with a firewall rule. Only ports 443 and perhaps 80 are required to be exposed.</li>
</ul>

</div>
`;
export default htmlContent
