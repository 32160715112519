const htmlContent = `
<div id="markdown">
<h1 id="index">Index</h1>
<p>2024-08: <a href="/aks-agfc">AKS &#39;Application Gateway for Containers&#39; infra-module</a>  </p>
<p>2024-07: <a href="/azure-wi">Get Azure workload-identity running with your software</a>  </p>
<p>2022-11: <a href="/terraform-statefile-azure">How to use the terraform statefile. Part 4: Azure</a>  </p>
<p>2022-06: <a href="/matomo-traefik">Run Matomo behind Traefik</a>  </p>
<p>2021-11: <a href="/rclone-ionos">How to use rclone with ionos (profitbricks) S3</a>  </p>
<p>2021-05: <a href="/terraform-statefile-aws">How to use the terraform statefile. Part 3: AWS</a>  </p>
<p>2021-03: <a href="/terraform-statefile-gcp">How to use the terraform statefile: Part 2: GCP</a>  </p>
<p>2021-03: <a href="/terraform-statefile">How to use the terraform statefile in public clouds</a>  </p>

</div>
`;
export default htmlContent
