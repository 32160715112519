const htmlContent = `
<div id="markdown">
<h1 id="aks-application-gateway-for-containers-infra-module">AKS &#39;Application Gateway for Containers&#39; infra-module</h1>
<h2 id="overview">Overview</h2>
<p>This infra-module is a blueprint for the usage of the AKS &#39;Application Gateway For Containers&#39; (AGFC). AGFC is Azure&#39;s latest offering to route traffic to Ingress objects in a dynamic way and supersedes <a href="https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-overview">AGIC</a>. 
AGFC implements the API Gateway  which is an open source project. AGFC also supports the use of HttpRoute objects which enhances the separation of concerns between the platform team and the dev teams.</p>
<p>This module is designed to remain functional for a long period of time. Therefore, it</p>
<ul>
<li>Uses specific versions and tags</li>
<li>Ensures the code is largely self-contained, with only Podman as a prerequisite</li>
<li>Scripts are idempotent and will &#39;exit on first error&#39;</li>
</ul>
<h2 id="sequence-of-steps">Sequence of Steps</h2>
<ol>
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/20-create-rg-and-cluster.sh">20-create-rg-and-cluster.sh</a></li>
</ol>
<ul>
<li>creates &#39;resource group&#39;</li>
<li>creates AKS cluster</li>
</ul>
<ol start="2">
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/30-create-managed-identity-for-alb-controller.sh">30-create-managed-identity-for-alb-controller.sh</a></li>
</ol>
<ul>
<li>creates &#39;managed identity&#39;</li>
<li>assigns &#39;Reader&#39; role to managed identity&#39;s principal, </li>
<li>sets up federation for the managed identity with AKS OIDC issuer</li>
</ul>
<ol start="3">
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/35-install-alb-controller.sh">35-install-alb-controller.sh</a></li>
</ol>
<ul>
<li>installs ALB-controller helm chart</li>
</ul>
<ol start="4">
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/40-create-ag.sh">40-create-ag.sh</a></li>
</ol>
<ul>
<li>creates the &#39;Application Gateway for Containers&#39; resource</li>
<li>creates a frontend resource in the &#39;App Gateway for Containers&#39;</li>
<li>creates the &#39;AppGw for Containers&#39; association</li>
</ul>
<ol start="5">
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/45-create-subnet-for-AG.sh">45-create-subnet-for-AG.sh</a></li>
</ol>
<ul>
<li>creates subnet</li>
<li>assigns &#39;AppGw for Containers Configuration Manager&#39; role to the &#39;resource group&#39; of the AKS cluster</li>
<li>assigns &#39;Network Contributor&#39; permission for join to association subnet</li>
</ul>
<ol start="6">
<li><a href="https://github.com/ludwigprager/aks-agfc-module/blob/master/50-deploy-gateway-and-application.sh">50-deploy-gateway-and-application.sh</a></li>
</ol>
<h2 id="code">Code</h2>
<p>Find the code in my github: <a href="https://github.com/ludwigprager/aks-agfc-module">https://github.com/ludwigprager/aks-agfc-module</a></p>
<h2 id="prerequisites">Prerequisites</h2>
<ul>
<li>Podman: Ensure Podman is installed and properly configured on your system. The modules was tested on linux but might work on WSL, too.</li>
<li>You have access to an Azure account with Administrator permissions to a subscription.</li>
</ul>
<h2 id="step-by-step-guide">Step-by-Step Guide</h2>
<ol>
<li><p>Clone the Repository</p>
<pre><code>git clone https://github.com/ludwigprager/aks-agfc-module.git
</code></pre>
</li>
<li><p>Configuration</p>
<ul>
<li>select or create a subscription for this POC to run.</li>
</ul>
</li>
<li><p>Run Idempotent Scripts<br> Execute the provided scripts. These scripts are designed to be idempotent, meaning they can be run multiple times without causing issues. They will also exit on the first error encountered to prevent further issues.</p>
<pre><code>./aks-agfc-module/10-create.sh
</code></pre>
</li>
</ol>
<h1 id="verify-success">Verify Success</h1>
<p>At the end of the installation, a curl will access the ingress via a azure-generated DNS entry.
The script will print the response.</p>
<h1 id="teardown">Teardown</h1>
<p>Terminate the session by running:</p>
<pre><code>./aks-agfc-module/90-teardown.sh
</code></pre>
<p>This will delete all created resources in the Azure cloud. </p>
<h1 id="references">References</h1>
<h2 id="current-solution-application-gateway-for-containers-agfc">Current solution: Application Gateway For Containers (AGFC)</h2>
<p><a href="https://learn.microsoft.com/en-us/azure/application-gateway/for-containers/quickstart-create-application-gateway-for-containers-managed-by-alb-controller?tabs=new-subnet-aks-vnet">https://learn.microsoft.com/en-us/azure/application-gateway/for-containers/quickstart-create-application-gateway-for-containers-managed-by-alb-controller?tabs=new-subnet-aks-vnet</a><br><a href="https://glossary.cncf.io/api-gateway/">https://glossary.cncf.io/api-gateway/</a>
<a href="https://www.cncf.io/blog/2022/08/16/the-new-kubernetes-gateway-api-and-its-use-cases/">https://www.cncf.io/blog/2022/08/16/the-new-kubernetes-gateway-api-and-its-use-cases/</a><br><a href="https://www.cncf.io/blog/2022/02/09/why-you-need-an-api-gateway-to-manage-access-to-your-apis/">https://www.cncf.io/blog/2022/02/09/why-you-need-an-api-gateway-to-manage-access-to-your-apis/</a><br><a href="https://www.cncf.io/blog/2023/08/14/the-future-of-api-gateways-on-kubernetes/">https://www.cncf.io/blog/2023/08/14/the-future-of-api-gateways-on-kubernetes/</a>  </p>
<p><a href="https://github.com/kubernetes-sigs/gateway-api">https://github.com/kubernetes-sigs/gateway-api</a>  </p>
<h2 id="previous-solution-application-gateway-ingress-controller-agic">Previous solution: Application Gateway Ingress Controller (AGIC)</h2>
<p><a href="https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-overview">https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-overview</a><br><a href="https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-install-new">https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-install-new</a><br><a href="https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-install-existing">https://learn.microsoft.com/en-us/azure/application-gateway/ingress-controller-install-existing</a><br><a href="https://azure.github.io/application-gateway-kubernetes-ingress/">https://azure.github.io/application-gateway-kubernetes-ingress/</a><br><a href="https://azure.github.io/application-gateway-kubernetes-ingress/tutorials/tutorial.general/">https://azure.github.io/application-gateway-kubernetes-ingress/tutorials/tutorial.general/</a><br><a href="https://techcommunity.microsoft.com/t5/apps-on-azure-blog/application-gateway-for-containers-vs-application-gateway/ba-p/3914901">https://techcommunity.microsoft.com/t5/apps-on-azure-blog/application-gateway-for-containers-vs-application-gateway/ba-p/3914901</a><br><a href="https://azure.github.io/application-gateway-kubernetes-ingress/annotations/">https://azure.github.io/application-gateway-kubernetes-ingress/annotations/</a><br><a href="https://azure.microsoft.com/en-us/blog/application-gateway-ingress-controller-for-azure-kubernetes-service/">https://azure.microsoft.com/en-us/blog/application-gateway-ingress-controller-for-azure-kubernetes-service/</a>  </p>

</div>
`;
export default htmlContent
