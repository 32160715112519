import { render, staticRenderFns } from "./blog.vue?vue&type=template&id=33d7212c&scoped=true&"
import script from "./blog.vue?vue&type=script&setup=true&lang=js&"
export * from "./blog.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./blog.vue?vue&type=style&index=0&id=33d7212c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d7212c",
  null
  
)

export default component.exports