const htmlContent = `
<div id="markdown">
<h1 id="azure-workload-identity-infra-module">Azure &#39;workload identity&#39; infra-module</h1>
<h2 id="overview">Overview</h2>
<p>This infra-module serves as an example implementation of Azure&#39;s &#39;workload identity.&#39; There is already a POC from Microsoft on this topic, along with some excellent presentations. However, the code is not immediately functional. Therefore, this module:</p>
<ul>
<li>Uses specific versions and tags</li>
<li>Ensures the code is largely self-contained, with only Podman as a prerequisite</li>
<li>Scripts are idempotent and will &#39;exit on first error&#39;</li>
</ul>
<p>Find the code in my github: <a href="https://github.com/ludwigprager/azure-workload-identity-POC">https://github.com/ludwigprager/azure-workload-identity-POC</a></p>
<h2 id="prerequisites">Prerequisites</h2>
<ul>
<li>Podman: Ensure Podman is installed and properly configured on your system. The modules was tested on linux but might work an macos and WSL, too.</li>
<li>You have access to an Azure account with Administrator permissions to a subscription.</li>
</ul>
<h2 id="step-by-step-guide">Step-by-Step Guide</h2>
<ol>
<li><p>Clone the Repository</p>
<pre><code>git clone https://github.com/ludwigprager/azure-workload-identity-POC.git
</code></pre>
</li>
<li><p>Configuration</p>
<ul>
<li>select a unique name for the <a href="https://github.com/ludwigprager/azure-workload-identity-POC/blob/master/set-env.sh#L7"><code>key vault</code></a></li>
<li>select or create a subscription for this POC to run.</li>
</ul>
</li>
<li><p>Run Idempotent Scripts<br> Execute the provided scripts. These scripts are designed to be idempotent, meaning they can be run multiple times without causing issues. They will also exit on the first error encountered to prevent further issues.</p>
<pre><code>./azure-workload-identity-POC/10-create.sh &lt;your-subscription-name&gt;
</code></pre>
</li>
</ol>
<h1 id="verify-success">Verify Success</h1>
<p>At the end of the installation, a pod will attempt to read a secret from the key vault and write it to a log file.
The script will display the log file to verify that the secret has been successfully retrieved.</p>
<h1 id="teardown">Teardown</h1>
<p>Terminate the session by running:</p>
<pre><code>./azure-workload-identity-POC/90-teardown.sh
</code></pre>
<p>This will delete all created resources in the Azure cloud. </p>
<h1 id="troubleshooting">Troubleshooting</h1>
<h2 id="a-vault-with-the-same-name-already-exists">A vault with the same name already exists</h2>
<p>If the deployment fails with <em>Message: A vault with the same name already exists in deleted state.</em> you need to provide a different name for the <a href="https://github.com/ludwigprager/azure-workload-identity-POC/blob/master/set-env.sh#L7"><code>key vault</code></a>.
Key vault names cannot be reused immediately. So if you deleted the deployment and started over without changing the key vault name this error will appear.<br>Also note, that key vault names need to be globally unique.</p>
<h1 id="references">References</h1>
<p><a href="https://github.com/Azure/azure-workload-identity/blob/main/docs/book/src/introduction.md">https://github.com/Azure/azure-workload-identity/blob/main/docs/book/src/introduction.md</a><br><a href="https://azure.github.io/azure-workload-identity/docs/quick-start.html">https://azure.github.io/azure-workload-identity/docs/quick-start.html</a><br><a href="https://github.com/Azure/azure-workload-identity/pkgs/container/azure-workload-identity%2Fmsal-go">https://github.com/Azure/azure-workload-identity/pkgs/container/azure-workload-identity%2Fmsal-go</a>  </p>
<p><a href="https://www.youtube.com/watch?v=paaFMgWpCjQ">https://www.youtube.com/watch?v=paaFMgWpCjQ</a><br><a href="https://www.youtube.com/watch?v=i2GobU0Wg48">https://www.youtube.com/watch?v=i2GobU0Wg48</a>  </p>

</div>
`;
export default htmlContent
