import { render, staticRenderFns } from "./rclone-ionos.vue?vue&type=template&id=50f873a3&scoped=true&"
import script from "./rclone-ionos.vue?vue&type=script&setup=true&lang=js&"
export * from "./rclone-ionos.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./rclone-ionos.vue?vue&type=style&index=0&id=50f873a3&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f873a3",
  null
  
)

export default component.exports