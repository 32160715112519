<script setup>

  import Navbar from '@/components/Navbar'
  import htmlContent from '@/assets/generated/chat-index.html'
  const raw3 = htmlContent

</script>


<template>
  <div style="  border: 5px solid #aaa; ">
    <navbar :lightTheme="true" :blogpost="true" />
    <v-row style="height: 90px; width: 100%; background-color: grey">
      <v-col :cols="6" class="d-flex align-center">
        <div id="raw" v-html="raw3"></div>
      </v-col>

      <v-col :cols="6" class="d-flex align-center" style="border: 2px solid black;" >
      </v-col> 

    </v-row>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/fcc.scss"; 
</style>
