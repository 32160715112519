const htmlContent = `
<div id="markdown">
<h1 id="the-terraform-statefile-gcp">The Terraform Statefile: GCP</h1>
<p>This is part 2 of 
<a href="/terraform-statefile">The Terraform Statefile in Public Clouds</a> and covers GCP.  </p>
<h2 id="quick-start">Quick Start</h2>
<ol>
<li>clone <a href="https://github.com/ludwigprager/gcp-terraform-base">this repo</a>.<pre><code> git clone https://github.com/ludwigprager/gcp-terraform-base
</code></pre>
</li>
<li>create a new project in the <a href="https://console.cloud.google.com/projectcreate">GCP console</a>
and assign the  project id to variable
<a href="https://github.com/ludwigprager/gcp-terraform-base/blob/main/set-env.sh#L6"><code>CLOUDSDK_CORE_PROJECT</code></a>
in <a href="https://github.com/ludwigprager/gcp-terraform-base/blob/main/set-env.sh">this file</a>.</li>
<li>authenticate by running <code>gcloud auth login</code> and call<pre><code>./gcp-terraform-base/10-deploy.sh
</code></pre>
</li>
</ol>
<h2 id="summary">Summary</h2>
<p>In this project you perform a GCP deployment with terraform
which can be repeated over and over from different installations or
in a CI/CD pipeline and will always create the exact resources as defined in .tf files.<br>It consists of 
these two idempotent steps:</p>
<ol>
<li>Creation of the storage for the terraform statefile.</li>
<li>Application of your IaC code.</li>
</ol>
<h2 id="step-1-20-tf-backend10-createsh">Step 1: 20-tf-backend/10-create.sh</h2>
<p>Create the container for the statefile if it doesn&#39;t already exist.<br>The full script is available <a href="https://github.com/ludwigprager/gcp-terraform-base/blob/main/20-tf-backend/10-create.sh">here</a>.</p>
<pre><code>source ../set-env.sh

function bucket-exists() {
  local bucket_name=\$1

  result=\$(gsutil  ls | grep -Fx gs://\${bucket_name}/)
  if [[ &quot;\$result&quot; == &quot;gs://\${bucket_name}/&quot; ]]; then
    return 0 
  else
    return 1
  fi
}

if bucket-exists &quot;\${BUCKET_NAME}&quot;  ; then 
  echo bucket \${BUCKET_NAME} already exists
else
  echo creating bucket \${BUCKET_NAME}
  gsutil mb gs://\${BUCKET_NAME}
fi
</code></pre>
<h2 id="step-2-30-main10-applysh">Step 2: 30-main/10-apply.sh:</h2>
<p>Eventually, start terraform after all preparation was done.
The <code>terraform init</code> won&#39;t change anything when called  again
but the <code>terraform apply</code> will modify all resources according to the .tf files.<br>The full script is available <a href="https://github.com/ludwigprager/gcp-terraform-base/blob/main/30-main/10-apply.sh">here</a>.</p>
<pre><code>source ../set-env.sh
source ./set-env.sh

terraform init \
  -input=false \
  -backend-config=&quot;bucket=\${BUCKET_NAME}&quot;

terraform apply -auto-approve
</code></pre>
<h2 id="the-tf-files">The .tf files</h2>
<p>These files contain the definition of resources that terraform is supposed to create.  </p>
<p>30-main/backend.tf:</p>
<pre><code>terraform {
  backend &quot;gcs&quot; {
    # unique name to identify the file within the bucket
    prefix  = &quot;my-tf-prefix&quot;
  }
}
</code></pre>
<p>30-main/provider.tf:</p>
<pre><code>provider &quot;google&quot; {
  project     = var.project_id
  region      = var.region
}
</code></pre>
<p>30-main/network.tf:</p>
<pre><code>module &quot;vpc&quot; {
  source  = &quot;terraform-google-modules/network/google&quot;
  version = &quot;3.5.0&quot;

  network_name = var.network_name
  project_id   = var.project_id

  subnets = [
    {
      subnet_name           = var.subnetwork_name
      subnet_ip             = var.cidr
      subnet_region         = var.region
      subnet_private_access = true
      description           = &quot;This subnet is managed by Terraform&quot;
    }
  ]

  secondary_ranges = {
    (var.subnetwork_name) = [
      {
                range_name    = &quot;my-pods-tf&quot;
                ip_cidr_range = &quot;10.11.0.0/16&quot;
      },
      {
                range_name    = &quot;my-services-tf&quot;
                ip_cidr_range = &quot;10.12.0.0/16&quot;
      },
    ]
  }
}
</code></pre>
<p>30-main/network-variables.tf:</p>
<pre><code>variable &quot;network_name&quot; {
  type    = string
}

variable &quot;subnetwork_name&quot; {
  type    = string
}

variable &quot;cidr&quot; {
  default     = &quot;10.16.0.0/16&quot;
}
</code></pre>

</div>
`;
export default htmlContent
