<template>
  <v-card class="welcome-container" variant="text">
    <v-card-title>{{ greeting1 }}</v-card-title>
    <v-card-text>{{ greeting2 }}</v-card-text>
    <v-card-actions class="button-container">
      <v-btn
        v-for="prompt in examplePrompts"
        :key="prompt"
        variant="tonal"
        @click="sendPrompt(prompt)"
        class="prompt-button"
      >
        {{ prompt }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {

  props: {
    prompts: {
      type: Array,
      required: false
    },
    greeting1: {
      type: String,
      required: true
    },
    greeting2: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      examplePrompts: this.prompts,
    };
  },
  methods: {
    sendPrompt(prompt) {
      this.$emit("prompt-selected", prompt);
    },
  },
};
</script>

<style scoped>
.welcome-container {
  text-align: center;
  margin: auto;
}

.button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prompt-button {
  margin-bottom: 10px;
}
</style>
