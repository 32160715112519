import axios from "axios";

export async function sendPrompt(message, chatid) {
  try {

    console.log('ai.js using id: ' + chatid)
    console.log('ai.js using message: ' + message)
    const response = await axios.post('https://proxy01.antex.chat/' + chatid, { question: message });

    console.log('ai.js: ' + JSON.stringify(response))
    return response.data;

  } catch (error) {
    if (error.response && error.response.status === 500) {
      return "I'm sorry, but I cannot answer that.";
    }
  }
}
