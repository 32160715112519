const htmlContent = `
<div id="markdown">
<h1 id="the-terraform-statefile-on-gcp">The Terraform Statefile on GCP</h1>
<h2 id="file-tree">File Tree</h2>
<pre><code>lprager@d01:~/work/cyagame/IaC/gke-tf\$ tree
.
├── 10-apply.sh
├── 20-tf-backend
│   ├── 10-create.sh
│   ├── 90-destroy.sh
│   ├── functions.sh
│   ├── notes.md
│   ├── README.md
│   └── set-env.sh
├── 30-main
│   ├── 10-apply.sh
│   ├── 90-destroy.sh
│   ├── apis.tf.disabled
│   ├── backend.tf
│   ├── gke.tf
│   ├── gke-variables.tf
│   ├── jumphost.tf
│   ├── jumphost-variables.tf
│   ├── network.tf
│   ├── network-variables.tf
│   ├── notes
│   ├── outputs.tf
│   ├── provider.tf
│   ├── README.md
│   ├── registry.tf
│   ├── sa_cya_ci_id_rsa.pub
│   └── service-account.tf
├── 90-teardown.sh
├── notes
└── set-env.sh
</code></pre>
<h2 id="20-tf-backend10-createsh">20-tf-backend/10-create.sh</h2>
<pre><code>#!/usr/bin/env bash

#set -x

set -eu
set -o pipefail


DIR=&quot;\$( cd &quot;\$( dirname &quot;\${BASH_SOURCE[0]}&quot; )&quot; &amp;&amp; pwd )&quot;
cd \$DIR
source ../../functions.sh
source ../../set-env.sh
source ../set-env.sh
source ./set-env.sh
source ./functions.sh

export CLOUDSDK_CORE_PROJECT=\${TF_VAR_project_id}

#result=\$(bucket-exists &quot;\${BUCKET_NAME}&quot;)
if bucket-exists &quot;\${BUCKET_NAME}&quot;  ; then 
  echo bucket \${BUCKET_NAME} already exists
else
  echo creating bucket \${BUCKET_NAME}
  gsutil mb gs://\${BUCKET_NAME}
fi
</code></pre>
<hr>

</div>
`;
export default htmlContent
