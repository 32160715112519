const htmlContent = `
<div id="markdown">
<h1 id="impressum">Impressum</h1>
<p>Dipl.-Inform. Ludwig Prager<br>Gschwendt 1<br>84559 Kraiburg a.Inn  </p>
<p>Telefon: <a href="tel:+49897602075">+49 89 7602075</a><br>Mobil:   <a href="tel:+4917648285442">+49 176 48285442</a><br>E-Mail:  <a href="mailto:lp@celp.de">lp@celp.de</a>  </p>
<p>Umsatzsteuer-Identifikationsnummer gem. § 27a UStG:<br>DE203694803</p>
<p>Inhaltlich Verantwortlicher gem. § 55 II RStV: Ludwig Prager</p>

</div>
`;
export default htmlContent
