import { useMainStore } from '@/stores';


export default {
    isLoading(){
        const stores = useMainStore();

        // console.log('stores active: ' + JSON.stringify(stores));

        //return this.$store.state.loading;
        return stores.loading;
    },
    isPc(){
        return this.$vuetify.breakpoint.mdAndUp;
    },
    isPhone(){
        return !this.isPc;
    },
    navbarHeight(){
        return this.isPc ? 90 : 70;
    },
    isLightNavbar(){
        const stores = useMainStore();
        //return this.$store.state.lightNavbar;
        return stores.lightNavbar;
    },
}
