<script setup>

import Navbar from '@/components/Navbar'
import htmlContent from '@/assets/generated/rclone-ionos.html'
const raw3 = htmlContent

</script>

<template>
  <div>
    <navbar :lightTheme="true" :blogpost="true" />
    <div id="raw" v-html="raw3"></div>
  </div>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/fcc.scss"; 
</style>

