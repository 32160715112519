import { defineStore } from 'pinia';

export const useMainStore = defineStore('main', {
    state: () => ({
        loading: true,
        lightNavbar: false,
        activeSection: 'home',
    }),
    actions: {
        setLoading(loading) {
            this.loading = !!loading;
        },
        setLightNavbar(light) {
            //console.log('stores setLightNavbar : %o section: %s', this.$state.lightNavbar, light);
            //console.log('stores setLightNavbar : %s light: %s', this.$state.lightNavbar, light);
            this.lightNavbar = !!light;
        },
        setActiveSection(section) {
            //console.log('stores setActiveSection state: %o section: %s', this.$state.activeSection, section);
            this.activeSection = section;
        },
    },
});
