var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.ChatBox,{attrs:{"chatid":'quirion',"greeting1":'Willkommen im Quirion Helpdesk',"greeting2":'Schreiben Sie Ihre Frage oder treffen Sie eine Auswahl!',"prompts":['Ich habe mein Passwort vergessen?', 'Was ist ein ETF?',


'Welche Arten von Coaching-Diensten bietet Quirion an?',
'Wie hoch sind die Kosten für die Coaching-Dienste?',
'Ist eine Mindestinvestition erforderlich, um sich für Coaching-Dienste anzumelden?',
'Sind die Coaches zertifizierte Fachleute?',
'Über welche Qualifikationen und Erfahrungen verfügen die Coaches?',
'Welche Themen werden in den Coachingsitzungen behandelt?',
'Muss ich Vorkenntnisse im Bereich der Geldanlage haben, bevor ich mich für das Coaching anmelde?',
'Gibt Quirion eine Garantie, dass meine Investitionen erfolgreich sind?',
'Wie lange dauern die Coaching-Sitzungen?',
'Kann ich Ratschläge zu bestimmten Investitionen erhalten, die ich in Betracht ziehe?',
'Ist es möglich, eine Einzelsitzung mit einem Coach zu buchen?',
'Welche Zahlungsarten akzeptieren Sie?',
'Wann kann ich mit einer Rückmeldung rechnen, nachdem ich eine Anfrage gestellt habe?',
'Bieten Sie irgendwelche Rabatte oder Anreize an?',
'Wie oft sollte ich mich mit meinem Coach treffen? ',

'Was ist Quirion?',
'Wie funktioniert der Sparplan?',
'Fallen bei der Anlage in ETFs über Quirion Gebühren an?',
'In welche Arten von ETFs kann ich investieren?',
'Ist mein Geld sicher, wenn ich über Quirion investiere?',
'Kann ich im Bedarfsfall schnell auf mein Geld zugreifen?',
'Welche Art von Portfolioanalyse bietet Quirion an?',
'Wie oft sollte ich meine Anlagen überprüfen?',
'Stellt Quirion Bildungsressourcen für Anleger zur Verfügung?',
'Welche Hilfsmittel stehen mir zur Verfügung, um fundierte Entscheidungen über meine Anlagen zu treffen?',
'Wie eröffne ich ein Sparplankonto bei Quirion?',
'Welche Informationen muss ich bei der Einrichtung eines Kontos angeben?',
'Wie lange dauert es, ein Konto einzurichten?',
'Wie kann ich die Wertentwicklung meiner Anlagen verfolgen?',
'An wen kann ich mich wenden, wenn ich Fragen oder Bedenken zu meinen Anlagen habe?',

'Welche Dienstleistungen bieten Sie an?',
'Wie viel kostet es, ein Konto zu eröffnen?',
'Wie funktioniert Ihr Null-Kosten-Handel?',
'Gibt es irgendwelche Transaktionsgebühren?',
'Ist eine Mindestanlage erforderlich?',
'Bieten Sie Marktanalysen und Tutorials an?',
'Kann ich sofort nach der Eröffnung eines Kontos mit dem Handel beginnen?',
'Wie lange dauert der Prozess der Kontobewilligung?',
'Welche Arten von Konten bieten Sie an?',
'Wie hoch ist die Performancegebühr für Kunden, die mehr als 10.000 Euro investieren?',
'Wie sicher sind meine Gelder bei Quirion?',
'Kann ich von verschiedenen Geräten aus auf mein Konto zugreifen?',
'Hat Quirion eine mobile App?',
'Bieten Sie Kundensupport an?',
'Wie lauten die Bedingungen für die Nutzung der Dienstleistungen von Quirion? '









]}})
}
var staticRenderFns = []

export { render, staticRenderFns }