var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.ChatBox,{attrs:{"chatid":'pv-0',"greeting1":'Willkommen im PV Chat',"greeting2":'Schreiben Sie Ihre Frage oder treffen Sie eine Auswahl!',"prompts":['Ich habe mein Kennwort vergessen?', 'Wie teuer ist eine PV-Anlage?', 'Wie gross muß meine Dachfläche sein?',
'Welche Dienstleistungen bietet KFW 442?', 
'Wie kann ich das KFW 442-Förderprogramm in Anspruch nehmen?', 
'Gibt es Rabatte beim Kauf Ihrer Produkte und Dienstleistungen?', 
'Welche Arten von Elektrofahrzeugen sind mit den KFW 442-Ladestationen kompatibel?', 
'Wie viel kosten die KFW 442-Ladestationen?', 
'Gibt es Installationsanforderungen für KFW 442-Photovoltaiksysteme?', 
'Kann ich von KFW 442 ein Angebot für eine komplette Solaranlage erhalten?', 
'Bietet KFW 442 Finanzierungsmöglichkeiten an?', 
'Gibt es einen technischen Support nach dem Kauf?', 
'Welche Garantien oder Gewährleistungen bieten Sie auf Ihre Produkte und Dienstleistungen?', 
'Welche Wartung ist bei KFW 442-Photovoltaikanlagen erforderlich?', 
'Haben Sie einen Tipp für die Auswahl der richtigen Komponenten für meine Solaranlage?', 
'Wie lange dauert die Installation einer KFW 442-Photovoltaikanlage?', 
'Sind die KFW 442-Ladestationen mit Elektroautos anderer Hersteller kompatibel?', 
'Gibt es besondere Sicherheitsvorschriften für die Installation von KFW 442-Photovoltaikanlagen? ',
'Was ist die KfW-Förderung 442?',
'Wie funktioniert die KfW-Förderung 442?',
'Gibt es Anspruchsvoraussetzungen für die KfW-Förderung 442?',
'Entstehen Kosten für die Inanspruchnahme der KfW-Förderung 442?',
'Wie lange dauert die Installation meiner PV-Anlage nach Beantragung der KfW-Förderung 442?',
'Welche Arten von Solarstromanlagen werden mit der KfW-Förderung 442 gefördert?',
'Welche Garantie gibt es bei der KfW-Förderung 442?',
'Bietet Energiekonzepte Deutschland Wartung und technischen Support für die KfW-Förderung 442 an?',
'Wie viel Strom kann ich mit meiner PV-Anlage voraussichtlich erzeugen?',
'Wie hoch ist die zu erwartende Lebensdauer meiner PV-Anlage?',
'Kann ich die Vorteile der KfW-Förderung 442 auf eine andere Person oder Firma übertragen?',
'Übernimmt die KfW-Förderung 442 die Kosten für Batterien?',
'Welche weiteren Förderungen gibt es durch die KfW-Förderung 442?',
'Kann ich mit der KfW-Förderung 442 meinen Verbrauch und meine Einsparungen nachvollziehen?',
'Wie oft muss ich meinen Antrag für die KfW-Förderung 442 erneuern? '


]}})
}
var staticRenderFns = []

export { render, staticRenderFns }