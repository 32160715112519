const htmlContent = `
<div id="markdown">
<h1 id="using-the-rclone-command-with-the-ionos-cloud">Using the Rclone Command with the Ionos Cloud</h1>
<p>Here is a working rclone.conf for S3 in the ionos cloud (former: profitbricks).</p>
<p>It took me some time to find these settings since
the howto page <a href="https://www.ionos.de/digitalguide/server/knowhow/datenmigration-mit-rclone/">https://www.ionos.de/digitalguide/server/knowhow/datenmigration-mit-rclone/</a> didn&#39;t
not help much.</p>
<pre><code>[ionos]
type = s3
provider = Other
env_auth = true
access_key_id = ********************
secret_access_key = ****************************************
endpoint = https://s3-de-central.profitbricks.com:443
acl = private
</code></pre>
<p>Replace the asterisks with your credentials.  </p>
<h2 id="real-example-use-case">Real example use case</h2>
<p>In the following script
the whole bucket <code>g1-fileshare</code> is synced to the local directory <code>g1-fileshare</code>.</p>
<pre><code>#!/bin/bash

set -eu

DIR=&quot;\$( cd &quot;\$( dirname &quot;\${BASH_SOURCE[0]}&quot; )&quot; &amp;&amp; pwd )&quot;
pushd \$DIR

rclone \
  --config \$(realpath rclone.conf ) \
  sync ionos:g1-fileshare/ g1-fileshare/
</code></pre>

</div>
`;
export default htmlContent
