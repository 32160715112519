const htmlContent = `
<div id="markdown">
<h1 id="the-terraform-statefile-aws">The Terraform Statefile: AWS</h1>
<p>This is part 3 of 
<a href="/terraform-statefile">The Terraform Statefile in Public Clouds</a> and covers AWS.  </p>
<h2 id="quick-start">Quick Start</h2>
<ol>
<li>clone <a href="https://github.com/ludwigprager/aws-terraform-base">this repo</a>.<pre><code> git clone https://github.com/ludwigprager/aws-terraform-base
</code></pre>
</li>
<li>set your credentials in <code>~/.aws/credentials</code> and call<pre><code>./aws-terraform-base/10-deploy.sh
</code></pre>
</li>
</ol>
<h2 id="summary">Summary</h2>
<p>The commands are almost the same as in <a href="/terraform-statefile-gcp">Part 2</a> which is about GCP.
But the creation of the backend with the statefile is completely different. </p>
<h2 id="step-1-20-tf-backend10-createsh">Step 1: 20-tf-backend/10-create.sh</h2>
<p>Create the container for the statefile if it doesn&#39;t already exist.<br>The procedure is significantly longer than the <a href="https://www.celp.de/terraform-statefile-gcp">GCP version</a>.
It follows the approach recommended by AWS and uses a DynamoDB table for synchronisation.<br><a href="https://github.com/ludwigprager/aws-terraform-base/blob/main/20-tf-backend/10-create.sh">Here is the file on github</a></p>
<h2 id="step-2-30-main10-applysh">Step 2: 30-main/10-apply.sh:</h2>
<p>Eventually, start terraform after all preparation was done.
The <code>terraform init</code> won&#39;t change anything when called  again
but the <code>terraform apply</code> will modify all resources according to the .tf files.<br><a href="https://github.com/ludwigprager/aws-terraform-base/blob/main/30-main/10-apply.sh">This file on github</a></p>
<pre><code>source ../set-env.sh

terraform init \
  -input=false \
  -backend-config=&quot;bucket=\${BUCKET_NAME}&quot; \
  -backend-config=&quot;region=\${TF_VAR_backend_region}&quot; \
  -backend-config=&quot;dynamodb_table=\${DYNAMODB_TABLE}&quot;

terraform apply -auto-approve
</code></pre>
<h2 id="the-tf-files">The .tf files</h2>
<p>These files contain the definition of resources that terraform is supposed to create.<br>In this part, for simplicity, it&#39;s only a registry.</p>
<p>30-main/backend.tf:</p>
<pre><code>terraform {  
    backend &quot;s3&quot; {
        encrypt        = true
        key            = &quot;poc1/terraform.tfstate&quot;
    }
}
</code></pre>
<p>30-main/provider.tf:</p>
<pre><code>provider &quot;aws&quot; {
  profile = &quot;default&quot;
  region  = var.aws_region
}

terraform {
  required_providers {
    aws = {
      source  = &quot;hashicorp/aws&quot;
      version = &quot;&gt;= 3.40&quot;
    }
  }
}
</code></pre>
<p>30-main/main.tf:</p>
<pre><code>resource &quot;aws_ecr_repository&quot; &quot;repo&quot; {
  name                 = &quot;g1&quot;
}
</code></pre>
<p>30-main/network-variables.tf:</p>
<pre><code>variable &quot;aws_region&quot; {
  description = &quot;AWS region&quot;
  type        = string
  default     = &quot;eu-central-1&quot;
}
</code></pre>

</div>
`;
export default htmlContent
