const htmlContent = `
<div id="markdown">
<h1 id="azure">Azure</h1>
<p>This is the Azure part of 
<a href="/terraform-statefile">this post</a>  </p>
<p>Pls. see the README from my git repository:  </p>
<p><a href="https://github.com/ludwigprager/azure-terraform-base/blob/master/README.md"><img src="https://www.celp.de/img/md/atp.png" alt="azure-terraform-base repository" title="azure-terraform-base repository on github"></a></p>

</div>
`;
export default htmlContent
