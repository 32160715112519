
<script setup>
console.log('hello script setup')
import Navbar from '@/components/Navbar'
//import MyFooter from '@/components/MyFooter'
import htmlContent from '@/assets/generated/p1.html'

//const msg = 'Hello!'
const raw3 = htmlContent

</script>


<template>
  <div>
  <navbar :lightTheme="true" :blogpost="true" />
<div id="raw" v-html="raw3"></div>
  </div>
<!--
  <MyFooter />
<p>Using v-html directive: <span v-html="raw3"></span></p>


  <button @click="log">{{ msg }}</button>
  <div>{{ raw3 }}</div>
<template src="@/assets/generated/gdpr.html"></template>
-->
</template>


<style scoped>
  @import "@/assets/css/fcc.scss"; 
</style>
